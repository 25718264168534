import React, { useContext } from 'react';
import { Button, Jumbotron, Card, Container, Tab, Row, Col, ListGroup } from "react-bootstrap";
import { FaFilePdf, FaRegClock } from "react-icons/fa";
import { CoopContext } from "./context";
import { CoopDocData } from './perl'

export function Articles() {
  const coop = useContext(CoopContext)
  const articles = coop.events.filter(u => u.isArticle)
  const firstLink = articles[0] ? `#link${articles[0].id}` : ''
  const url = `https://or.justice.cz/ias/ui/rejstrik-$firma?ico=${
    coop.legalNo}&jenPlatne=PLATNE&polozek=50&typHledani=STARTS_WITH`
  return (
    <Container className='m-3'>
      <Card>
        <Card.Title className='p-3'>
          <h2>Stanovy</h2>
        </Card.Title>
        <Card.Subtitle className="pl-3 mb-2 text-muted">
          Oficiální stanovy družstva, včetně historických verzí
         </Card.Subtitle>
        <Card.Text className='p-3'>
          <Tab.Container defaultActiveKey={firstLink}>
            <Row>
              <Col sm={4}>
                <ListGroup>
                  {articles.map(u =>
                    <ListGroup.Item key={u.id} action href={`#link${u.id}`}>
                      <ArticlesInfo u={u} />
                    </ListGroup.Item>)}
                </ListGroup>
              </Col>
              <Col sm={8}>
                <Tab.Content>
                  {coop.events.filter(u => true
                  ).map(u =>
                    <Tab.Pane key={u.id} eventKey={`#link${u.id}`}>
                      <ArticlesPreview u={u} />
                    </Tab.Pane>)}
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Card.Text>
        <Card.Footer className="text-muted">
          Kompletní znení ze
            <a href={url}>{' sbírky listin'}</a>
        </Card.Footer>
      </Card>
    </Container>
  )
}

function ArticlesPreview({ u }: { u: CoopDocData }) {
  return (
    <Jumbotron>
      <Button className='float-right mt-1' href={`/coop/doc/${u.id}`}>
        <FaFilePdf />{' Stáhnout'}</Button>
    </Jumbotron>
  )
}
function ArticlesInfo({ u }: { u: CoopDocData }) {
  return (
    <div>
      <h5>{u.subject}</h5>
      <div><FaRegClock /> {u.createdCze}</div>
    </div>
  )
}

import React, { ReactNode } from 'react'
import Dialog from 'react-bootstrap-dialog';
import { Button, Spinner, ButtonProps } from 'react-bootstrap';
// import Toast from 'react-bootstrap/Toast'
//declare const Toast: any // v v1.0.0-beta.11 v index.d.ts neni...

type MyButtonProps<In, Out> = ButtonProps & {
	className?: string,
	icon?: ReactNode,
	text?: string,
	loadingText?: string,
	showUploadProgress?: boolean, // zatim nefunkcni / nepodporujme
	toastText?: string, // unsupported
	// formId: string, // Element? vzdy jeho parent?
	getData?: (formData: null | FormData) => In, // fD pokud je. default identity
	validate?: (data: In) => void, // throws on error
	confirmAlert?: string,
	dangerAlert?: string,
	onAction: (data: In) => Promise<Out>, // e.g. API.coopUploadPhoto
	successAlert?: string,
	onError?: (err: string) => void,
	// myslenka - jestli onOK vrati promise, tak toc koleckem az do spleni?
	onOK?: (data: Out) => void | Promise<void>, // e.g. coop.load
	resetForm?: boolean
	// willNavigate?: boolean, // zatim nepodporujeme
}


// url: string, data: any, 
// onUploadProgress: (p: number) => void
// onError(e: string) => void
// onOK(data: any) => void
/*
async function postUrl(url: string, data: any, onUploadProgress: (p: number) => void): Promise<void> {
	// mame fetch cookies?
	axios.post(url, data, { onUploadProgress })
	// const p = e.total ? (Math.round(e.loaded / e.total * 100) + ' %') : '';
}
*/

type MyButtonState = {
	running: boolean,
	loading: boolean,
	uploadingProgress: null | number,
	toastShow: boolean,
}

export class MyButton<In, Out>
	extends React.Component<MyButtonProps<In, Out>, MyButtonState> {

	public state: MyButtonState = {
		running: false, // async op in progress?
		toastShow: false,
		loading: false,
		uploadingProgress: null
	}

	preDialog: any = null
	successDialog: any = null
	errorDialog: any = null

	doValidate(data: In): boolean {
		const validate = this.props.validate
		if (!validate) return true
		try {
			validate(data)
			return true
		} catch (e) {
			this.errorDialog.show({
				body: `${e}`, // au, nejak hezceji!
				actions: [
					// nejake Vice informaci / Kontakt / Nahlasit ?
					Dialog.DefaultAction('OK', () => { return }, 'btn-danger')
				]
			})
			return false
		}
	}

	doGetData(btnEl: HTMLButtonElement): In {
		const formEl = btnEl.form
		const formData = formEl ? new FormData(formEl) : null
		const getData = this.props.getData
		if (!getData && (formData === null)) throw new Error('No form to post?');
		return getData ? getData(formData) : formData as unknown as In
	}

	doPre(next: () => void) {
		const cA = this.props.confirmAlert, dA = this.props.dangerAlert
		if (cA && dA) throw new Error("both confirm/danger?")
		if (cA || dA) {
			this.preDialog.show({
				body: cA || dA, // text
				actions: [
					Dialog.CancelAction(),
					Dialog.DefaultAction('OK', () => { next() }, dA ? 'btn-danger' : null)
				]
			})
		} else {
			next()
		}
	}

	resetButton(btnEl: HTMLButtonElement, width: number, origStyleWidth: null | string) {
		this.setState({ running: false, loading: false, uploadingProgress: null })
		if (this.props.showUploadProgress) btnEl.style.width = origStyleWidth
	}

	async doAction(data: In, btnEl: HTMLButtonElement, next: (out: Out) => void) {
		this.setState({ loading: true, uploadingProgress: null, toastShow: false })
		//	const doAction = async (btnEl: HTMLButtonElement) => {
		const width = btnEl.offsetWidth // clientWidth
		const origStyleWidth = btnEl.style.width
		if (this.props.showUploadProgress) btnEl.style.width = `${width}px`
		try {
			this.props.onAction(data
			).then((out) => {
				this.resetButton(btnEl, width, origStyleWidth)
				next(out)
			}).catch((e) => {
				console.log("Error in async doAction", e)
				this.doError(`${e}`)
				this.resetButton(btnEl, width, origStyleWidth)
			})
		} catch (e) {
			console.log("Error during launching doAction", e)
			// chceme upozornovat volajiciho jen na chyby v async zpracovani
			// nebo i na chyby pri zpracovavani akce pred odeslanim?
			this.doError(`${e}`)
			this.resetButton(btnEl, width, origStyleWidth)
		}
	}

	doError(error: string) {
		console.log("MyButton: error", error)
		// var error = xhr.getResponseHeader('X-ISpis-Error') || netError;
		const err = error || 'Unknown error';
		if (this.props.onError) {
			this.props.onError(err)
		} else {
			this.errorDialog.show({
				body: err, // au, nejak hezceji!
				actions: [
					// nejake Vice informaci / Kontakt / Nahlasit ?
					Dialog.DefaultAction('OK', () => { }, 'btn-danger')
				]
			})
		}
		// if (onError) onError(error, btn); else alert(error);
	}

	doPost(btnEl: HTMLButtonElement, out: Out, next: () => void) {
		const successAlert = this.props.successAlert
		// co kdyz dojde k exception v onOK ? zobrazit?
		// if (p.toastText) this.setState({ toastShow: true })
		if (successAlert) this.successDialog.showAlert(successAlert)
		this.doPostAlert(btnEl, out, next)
		/*
		if (successAlert) {
			this.successDialog.show({
				body: successAlert,
				actions: [
					Dialog.DefaultAction('OK', () => {
						this.doPostAlert(btnEl, out, next)
					})
				]
			})
		} else {
			this.doPostAlert(btnEl, out, next)
		}
		*/
	}

	doPostAlert(btnEl: HTMLButtonElement, out: Out, next: () => void) {
		const onOK = this.props.onOK
		if (onOK) {
			this.setState({ running: true }) // tak jeste chvilku..
			window.setTimeout(() => {
				const promise = onOK(out)
				if (promise) {
					console.log("MyButton: onOK returned promise, holding on... ");
					this.setState({ loading: true })
					promise.then(() => {
						this.setState({ loading: false })
						next();
					}).catch((e) => {
						console.log("MyButton: onOK() error: ", e);
						next();
					})
				} else {
					next()
				}
			}, 10) // redraw btn.disabled
		} else {
			next();
		}
		if (this.props.resetForm) {
			const form = btnEl.form as unknown as HTMLFormElement
			if (form) form.reset()
		}
	}

	async onClick(e: Event) {
		e.preventDefault()
		const btnEl = e.target as unknown as HTMLButtonElement
		const data = this.doGetData(btnEl)
		// console.log('OK, poustim tu nadheru', data)
		if (this.doValidate(data)) {
			this.setState({ running: true })
			this.doPre(() => {
				this.doAction(data, btnEl, (out) => {
					this.doPost(btnEl, out, () => {
						this.setState({ running: false })
					})
				})
			})
		}
	}

	componentWillUnmount() {
		// TODO: zrus co jde...
		if (this.state.running) {
			console.log('MyButton.componentWillUnmount() still running!')
		}
	}

	render() {
		const props = this.props
		const loading = this.state.loading
		const running = this.state.running
		const uploadingProgress = this.state.uploadingProgress
		return (<>
			<Button
				block={props.block}
				className={props.className}
				variant={props.variant}
				size={props.size}
				href={props.href}
				disabled={running}
				onClick={(e: any) => e && this.onClick(e as unknown as Event)}
			>
				{loading &&
					<><Spinner size='sm' animation='border' />
						{(uploadingProgress != null && props.showUploadProgress) ?
							` ${uploadingProgress}%` :
							props.loadingText ? ` ${props.loadingText}` :
								(props.text ? ` ${props.text}` : '')}
					</>}
				{!loading &&
					<>
						{props.icon ? props.icon : null}
						{props.icon && props.text ? ' ' : null}
						{props.text ? props.text : null}
					</>}
			</Button>
			<Dialog ref={(el) => { this.preDialog = el }} />
			<Dialog ref={(el) => { this.successDialog = el }} />
			<Dialog ref={(el) => { this.errorDialog = el }} />
		</>)
	}
}
/*
				{props.toastText &&
					<div style={{ position: 'relative', minHeight: '100px' }}>
						<Toast style={{ position: 'absolute', top: 0, right: 0 }}
							transition={undefined as unknown as boolean} animation
							show={this.state.toastShow} delay={3000} autohide>
							<Toast.Body>{props.toastText}</Toast.Body>
						</Toast>
					</div >}
					*/

// teoreticky i nejaky helper ala dom_post_form, berouci 
// Button a delajici Alert nebo Toast nebo Redirect nebo custom()

// wrapper nad formularem, resi validaci, loading spinner, alert-box a default akce

/*
export function dom_post_form(
	btn: HTMLButtonElement,
	url: string,
	onError: null | ((err: string, btn: HTMLButtonElement) => void),
	onOK: null | ((data: any, btn: HTMLButtonElement) => void),
	opts?: DomPostOptions): boolean {
	if (opts == null) opts = {};
	const oldBtnHtml = $(btn).html();
	const form = opts.formId ? $('#' + opts.formId)[0] as HTMLFormElement : btn.form;
	if (!form) throw new Error('No form to post?');
	const formData = new FormData(form);
	// const url = $(btn.form).attr('action');
	$(btn).prop('disabled', 'disabled');
	// explicitly, '1 %' nezmensi
	if (!opts.loadingHtml) $(btn).width($(btn).width() || 0);
	const btnHtml = "<i class='fa fa-spin fa-spinner'></i>" +
		(opts.loadingHtml ? " " + opts.loadingHtml : '');
	$(btn).html(btnHtml);
	if (!url) url = form.action;
	$.ajax({
		url: url, data: formData, type: 'POST',
		processData: false, contentType: false,
		xhr: function () {
			if (opts == null) opts = {}; // cudne
			var xhr = $.ajaxSettings.xhr ? $.ajaxSettings.xhr() : null
			if (!xhr) throw new Error('No XHR');
			if (opts.showProgress) {
				xhr.upload.onprogress = function (e: ProgressEvent) {
					const p = e.total ?
						(Math.round(e.loaded / e.total * 100) + ' %') : '';
					$(btn).html("<i class='fa fa-spin fa-spinner'></i> " + p);
				}
				xhr.upload.onload = function () {
					$(btn).html(btnHtml);
				};
			}
			return xhr;
		},
		success: function (data: object) {
			if (opts == null) opts = {}; // cudne
			if (opts.resetForm) form.reset(); // snad je to ok..
			if ((!onOK && !opts.confirmHtml)
				|| opts.reloadWindow) return window.location.reload();
			$(btn).prop('disabled', '');
			if (!opts.willNavigate) $(btn).html(oldBtnHtml);
			if (onOK) window.setTimeout(function () {
				onOK(data, btn);
			}, 10); // redraw btn.disabled
			if (opts.confirmHtml) alert(opts.confirmHtml);
		},
	}).fail(function (xhr: any, textStatus: string, netError: string) {
		console.log(xhr, textStatus, netError);
		$(btn).prop('disabled', '');
		$(btn).html(oldBtnHtml);
		var error = xhr.getResponseHeader('X-ISpis-Error') || netError;
		if (!error) error = textStatus || 'Error uploading form';
		if (onError) onError(error, btn); else alert(error);
	});
	return false;
}
*/

import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, Route } from "react-router-dom"
import { CoopContext, AuthContext } from './context'
import { CoopData, CoopAuthData } from './perl';
import api from './api'
import Layout from './Layout'
import { Access } from './Access';
import { Contact } from './Contact';
import { Home } from './Home'
import { SignIn, SignOut, ResetPassword } from './SignIn'
import { Settings } from './Settings'
import { Billing } from './Billing'
import { Articles } from './Articles'
import { About } from './About'
import { Submit } from './Submit'

function App() {
  const initialAuthData = {
    email: null, invoices: [], logins: []
  } as unknown as CoopAuthData
  const initialCoopData = { events: [] } as unknown as CoopData
  const authMethods = {
    load: async () => {
      let authData = initialAuthData
      try {
        authData = await api.coopAuthData()
      } catch (e) {
        /* nada */
      }
      setAuth({ ...authData, ...authMethods })
      console.log("setAuth", authData)
    },
  }
  const coopMethods = {
    load: async () => {
      const coopData = await api.coopData()
      setCoop({ ...coopData, ...coopMethods })
      console.log("setCoop", coopData)
    },
  }
  const [coop, setCoop] = useState({ ...initialCoopData, ...coopMethods })
  const [auth, setAuth] = useState({ ...initialAuthData, ...authMethods })

  useEffect(() => {
    coop.load()
    auth.load()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <CoopContext.Provider value={coop}>
      <AuthContext.Provider value={auth}>
        <Router>
          <Layout>
            <Route path="/" exact component={Home} />
            <Route path="/vypis" component={About} />
            <Route path="/stanovy" component={Articles} />
            <Route path="/kontakt" component={Contact} />
            <Route path="/submit" component={Submit} />
            <Route path="/signin" component={SignIn} />
            <Route path="/signout" component={SignOut} />
            <Route path="/reset" component={ResetPassword} />
            <Route path="/access" component={Access} />
            <Route path="/photo" component={Settings} />
            <Route path="/invoices" component={Billing} />
          </Layout>
        </Router>
      </AuthContext.Provider>
    </CoopContext.Provider>
  );
}

export default App;

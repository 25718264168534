import React, { useContext } from 'react'
import { Redirect } from 'react-router'
import { AuthContext, CoopContext } from './context';
import { Form, Card, Container, Figure } from 'react-bootstrap';
import { FaUpload } from 'react-icons/fa';
import { MyButton } from './MyButton';
import API from './api'

export function Settings() {
  const auth = useContext(AuthContext)
  const coop = useContext(CoopContext)
  if (!auth.email) return <Redirect to='/signin?back=/settings' />
  return (
    <Container className='mt-3'>
      <Card>
        <Card.Header as='h3'>Nastavení</Card.Header>
        <Card.Body>
          <Card.Subtitle className="mb-3 text-muted">
            Nastavení úvodní fotografie:
          </Card.Subtitle>
          <Form>
            {coop.customPhotoUrl &&
              <>
                <Figure.Image src={coop.customPhotoUrl} fluid />
              </>}
            <Form.Control name="coopPhoto" type="file" />
            <Form.Text className="text-muted">
              Fotografie bude použita na úvodní stránce, místo
              náhledu adresy z Google Maps StreetView.
            </Form.Text>
            <MyButton
              text='Nahrát' icon={<FaUpload />}
              loadingText='Nahrávám' showUploadProgress
              confirmAlert="Fotografie bude nahrána"
              successAlert="Fotografie byla nahrána"
              toastText="OK, fotografie byla uložena"
              onAction={API.coopUploadPhoto}
              resetForm
              onOK={() => {
                console.log("photo onOK");
                return coop.load()
              }}
            />
            {/*
            <MyButton variant='outline-secondary'
              text='Vymazat' icon={<FaTrash />}
              dangerAlert="Skutečně smazat fotografii?"
              onAction={API.coopUploadPhoto}
              onOK={() => coop.load()}
            />
            */}
          </Form>
        </Card.Body>
      </Card >
    </Container >
  )
}
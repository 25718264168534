import React, { ReactNode, useContext } from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap'
import logo from './logo-shadow-64.png'
import { CoopContext, AuthContext } from './context';

function Header() {
  const coop = useContext(CoopContext)
  const auth = useContext(AuthContext)
  return (
    <Navbar bg="primary" variant="dark" expand="md">
      <Navbar.Brand href="/">
        <img
          alt=""
          src={logo}
          width="32"
          height="32"
          className="d-inline-block align-top"
        />
        <span style={{ textShadow: '1px 1px #555' }}>
          {' '}
          {coop.shortTitle}
        </span>
      </Navbar.Brand>

      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <LinkContainer exact to='/'>
            <Nav.Link>Úvod</Nav.Link>
          </LinkContainer>
          <LinkContainer to='/vypis'>
            <Nav.Link>O nás</Nav.Link>
          </LinkContainer>
          <LinkContainer to='/stanovy'>
            <Nav.Link>Stanovy</Nav.Link>
          </LinkContainer>
          <LinkContainer to='/kontakt'>
            <Nav.Link to="/kontakt">Kontakt</Nav.Link>
          </LinkContainer>
        </Nav>
      </Navbar.Collapse>
      {!auth.email && <Nav>
        <LinkContainer to='/signin'>
          <Nav.Link>Přihlásit</Nav.Link>
        </LinkContainer>
      </Nav>}
      {auth.email && <SignedIn />}
    </Navbar>
  )
}

function SignedIn() {
  const auth = useContext(AuthContext)
  const email = auth.email
  if (email === null) return null
  return <Nav>
    <NavDropdown title={email || ''} id="basic-nav-dropdown">
      <LinkContainer to='/photo'>
        <NavDropdown.Item>Nastavení</NavDropdown.Item>
      </LinkContainer>
      <LinkContainer to='/access'>
        <NavDropdown.Item>Uživatelé</NavDropdown.Item>
      </LinkContainer>
      <LinkContainer to='/invoices'>
        <NavDropdown.Item>Faktury</NavDropdown.Item>
      </LinkContainer>
      <NavDropdown.Divider />
      <LinkContainer to='/signout'>
        <NavDropdown.Item>Odhlásit</NavDropdown.Item>
      </LinkContainer>
    </NavDropdown>
  </Nav>
}

/*
    <Form inline>
      <FormControl type="text" placeholder="Search" className="mr-sm-2" />
      <Button variant="outline-success">Search</Button>
    </Form>
    */

function Layout({ children }: { children: ReactNode }) {
  return (
    <div>
      <Header />
      {children}
    </div>
  );
}

export default Layout;

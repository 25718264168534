import React, { useContext } from 'react'
import { Container, Card } from 'react-bootstrap'
import { CoopContext } from './context'
import { Turndown } from './components'

export function About() {
  const coop = useContext(CoopContext)
  const url = `https://or.justice.cz/ias/ui/rejstrik-$firma?ico=${
    coop.legalNo}&jenPlatne=PLATNE&polozek=50&typHledani=STARTS_WITH`
  return (
    <Container className='p-3'>
      <Card>
        <Card.Body>
          <Card.Title><h2>O nás</h2></Card.Title>
          <Card.Subtitle className="mb-2 text-muted">
            Výpis z obchodního rejstříku
          </Card.Subtitle>
          {coop.vypisHtml && <Card.Text>
            <Turndown html={coop.vypisHtml} />
          </Card.Text>}
        </Card.Body>
        <Card.Footer className="text-muted">
          Úplný výpis z
            <a href={url}>{' obchodního rejstříku'}</a>
        </Card.Footer>
      </Card>
    </Container>
  )
}
import React, { useContext } from 'react'
import { MyGmap } from './components';
import { Container, Card, Table } from 'react-bootstrap';
import { CoopContext } from './context';

export function Contact() {
  const coop = useContext(CoopContext)
  return (
    <Container className='m-3'>
      <Card>
        <Card.Title className='pl-3 pt-3'><h2>Kontakt</h2></Card.Title>
        <Table striped className='pl-4 pr-4'>
          <tbody>
            <tr><td>Název</td><td>{coop.longTitle}</td></tr>
            <tr><td>IČ</td><td>{coop.legalNo}</td></tr>
            <tr><td>Adresa</td><td>{coop.streetNo}, {coop.zipCity}</td></tr>
            {coop.contactPhone &&
              <tr><td>Telefon</td><td>{coop.contactPhone || '-'}</td></tr>}
            <tr><td>E-mail</td><td>
              <a href={`mailto:${coop.contactEmail}`}
              >{coop.contactEmail}</a></td></tr>
          </tbody>
        </Table>
        <MyGmap />
      </Card>
    </Container>
  )
}
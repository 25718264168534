import React, { useContext } from 'react'
import { Container, Card, Table, Badge } from 'react-bootstrap'
import { AuthContext } from './context'
import { Redirect } from 'react-router'
import { FaFileDownload } from 'react-icons/fa';
import { PaymentForProject } from './perl'

function InvoiceRow(i: PaymentForProject) {
  const url = `/accounting/download?order=${i.id}&invoicePdf=PDF`
  return (
    <tr key={i.id}>
      <td>{i.id}</td>
      <td>{i.issuedCze}</td>
      <td><em>{i.description}</em></td>
      <td style={{ textAlign: 'end' }}>{i.amountLocal}</td>
      <td>{i.paidCze ||
        <Badge variant="danger">Neuhrazeno</Badge>}
      </td>
      <td>{i.issuedCze &&
        <a href={url}><FaFileDownload />&nbsp;{`${i.id}.pdf`}</a>
      }</td>
    </tr>

  )
}
export function Billing() {
  const auth = useContext(AuthContext)
  console.log("Billing", auth)
  if (!auth.email) return <Redirect to='/signin' />
  return (
    <Container fluid className="mt-3">
      <Card>
        <Card.Header as='h3'>Faktury</Card.Header>
        <Card.Body>
          <Card.Subtitle className='mb-3 text-muted'>
            Přehled vystavených faktur:
          </Card.Subtitle>
          <Table responsive striped hover>
            <thead>
              <tr>
                <th>VS</th>
                <th>Vystaveno</th>
                <th>Popis</th>
                <th style={{ textAlign: 'end' }}>Částka</th>
                <th>Uhrazeno</th>
                <th style={{ minWidth: '150px' }}>Daňový doklad</th>
              </tr>
            </thead>
            <tbody>
              {auth.invoices.map(i => InvoiceRow(i))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </Container>
  )
}

import React, { useContext } from 'react'
import { MyGmap, Turndown } from './components'
import { Button, Alert, Jumbotron, Card, Table } from "react-bootstrap"
import { Image, Container, Row, Col } from 'react-bootstrap'
import { FaTrash, FaFile, FaPlus } from "react-icons/fa"
import { CoopContext, AuthContext } from "./context"
import { CoopDocData } from './perl'
import { LinkContainer } from 'react-router-bootstrap'
import { MyButton } from './MyButton'
import api from './api';
import { RouteComponentProps } from 'react-router';
import qs from 'querystring';
import { ResetPassword } from './SignIn';

export function Home({ location }: RouteComponentProps) {
  const qsP = qs.parse(location.search);
  console.log(location, qsP);
  if (qsP.reset) {
    return <ResetPassword email={qsP.email} reset={qsP.reset} />
  }
  return (
    <Container fluid>
      <Row>
        <Col md='8' className='mt-3'>
          <Events />
        </Col>
        <Col md='4' className='mt-3'>
          <Map />
        </Col>
      </Row>
    </Container>
  )
}

function Map() {
  let coop = useContext(CoopContext)
  //<Card style={{ maxWidth: '480px' }}>
  return (
    <Card style={{ minWidth: '240px', maxWidth: '480px' }}>
      {/* ctx.coop.coords &&
        <MyGmap lat={ctx.coop.coords.lat} lng={ctx.coop.coords.lng} /> */}
      {coop.customPhotoUrl
        && <Image src={coop.customPhotoUrl} fluid />}
      {coop.streetNo && <MyGmap />}
      <Card.Body>
        <h4>Kde nás najdete</h4>
        <h5>
          {coop.longTitle}
        </h5>
        <div>
          {coop.streetNo}<br />
          {coop.zipCity}<br />
        </div>
        {coop.contactEmail && <div>
          Email:
          <a href={`mailto:${coop.contactEmail}`}
          > {coop.contactEmail}</a>
        </div>}
        {coop.contactPhone ?
          <div>
            Telefon:
          <a href={`tel:${coop.contactPhone}`}
            > {coop.contactPhone}</a>
          </div>
          : <div>
            IČ: {coop.legalNo}
          </div>}
      </Card.Body>
    </Card >
  )
}

export function Events() {
  const coop = useContext(CoopContext)
  const auth = useContext(AuthContext)
  return (
    <Card className="p-3">
      <Card.Title>
        <LinkContainer to={auth.email ? '/submit' : '/signin'}>
          <Button className="float-right"
            variant={auth.email ? "success" : "light"}>
            <FaPlus />
            {' Vložit aktualitu'}
          </Button>
        </LinkContainer>
        <h2>{coop.longTitle}</h2>
      </Card.Title>
      <Card.Subtitle className="mb-2 text-muted">
        Aktuality
      </Card.Subtitle>
      <Card.Body>
        <Table striped responsive>
          <thead>
          </thead>
          <tbody>
            {coop.events.filter(u => auth.email || !u.restricted
            ).map(u => <EventRow key={u.id} u={u} />)}
          </tbody>
        </Table >
      </Card.Body>
    </Card>
  )
}
function EventRow({ u }: { u: CoopDocData }) {
  const coop = useContext(CoopContext)
  const auth = useContext(AuthContext)
  return (
    <>
      <tr>
        <td>
          {auth.email && <div className="float-right">
            <MyButton variant="link" icon={<FaTrash />}
              dangerAlert="Skutečně smazat aktualitu?"
              getData={() => { return { id: u.id } }}
              onAction={(data) => api.coopDeleteDoc(data)}
              onOK={() => coop.load()}
            />
          </div>}
          <div className="float-right">
            <a href={`mailto:${u.authorEmail}`}>{u.authorEmail}</a>
          </div>
          <div>
            <strong>{u.createdCze}</strong>
          </div>
          <div>
            {u.misto ?
              <Alert variant="warning">
                <h5>Pozvánka na členskou schůzi</h5>
                {u.subject}
              </Alert>
              : u.subject}
          </div>
          {(u.messageHtml) &&
            <Jumbotron>
              <Turndown html={u.messageHtml} />
            </Jumbotron>}
          {u.hasContent && <div className="mt-3">
            <Button href={`/coop/doc/${u.id}`}
              variant="primary">
              <FaFile className="mr-2" />
              {'Příloha '}
              <em>{u.fileNameExt}</em>
            </Button>
          </div>}
        </td>
      </tr>
      <tr>
        <td></td>
      </tr>
    </>
  )

}

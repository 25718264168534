import 'isomorphic-unfetch'
import { CoopData, CoopAuthData } from './perl';

// teoretcky vygenerovatelne ze swagger souboru...
// nahrada za perl.ts

/*
function enc(data: any) {
  const ret = [];
  for (let d in data)
    ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
  return ret.join('&');
}

function timeout(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}
*/

type PlainObject = { [key: string]: string }

function toFD(data: PlainObject): FormData {
  const fD = new FormData()
  for (let name of Object.keys(data)) { fD.append(name, data[name]) }
  return fD
}

function call(url: string, data: FormData | PlainObject | null): Promise<any> {
  const body = data ? ((data instanceof FormData) ? data : toFD(data)) : null
  console.log('FETCH', url, data, body)
  return fetch(url, {
    credentials: 'same-origin', // resend cookie
    method: 'POST', body
  }).then(function (response) {
    if (!response.ok) {
      const ispisErrEnc = response.headers.get('X-ISpis-Error')
      const ispisErr = ispisErrEnc // nejak dekodovat utf8...
      const error = ispisErr || response.statusText
      console.log("ERROR", error)
      throw new Error(error)
    }
    return response.json();
  }).then(function (json) {
    console.log('GOT', url, json)
    return json
  }).catch(function (connectionError) {
    throw new Error(connectionError)
  })
}

export default class {
  static async loginAuthorize(fD: FormData): Promise<void> {
    return call('/login/authorize', fD) as Promise<void>
  }
  static async loginSendReset({ email }: { email: string }): Promise<void> {
    return call('/login/sendReset', { email }) as Promise<void>
  }
  static async loginResetPassword(fD: FormData): Promise<void> {
    return call('/login/resetPassword', fD) as Promise<void>
  }
  static async settingsDenyAccess({ email }: { email: string }): Promise<void> {
    return call('/settings/denyAccess', { email }) as Promise<void>
  }
  static async loginLogout(): Promise<void> {
    return call('/login/logout', null) as Promise<void>
  }
  static async coopData(): Promise<CoopData> {
    return call('/coop/coopData', null) as Promise<CoopData>
  }
  static async coopAuthData(): Promise<CoopAuthData> {
    return call('/coop/authData', null) as Promise<CoopAuthData>
  }
  static async coopUploadPhoto(fD: FormData): Promise<void> {
    return call('/coop/settings', fD) as Promise<void>
  }
  static async coopSubmit(fD: FormData): Promise<void> {
    return call('/coop/submit', fD) as Promise<void>
  }
  static settingsCreateLogin(fD: FormData): Promise<void> {
    return call('/settings/createLogin', fD) as Promise<void>
  }
  static coopDeleteDoc({ id }: { id: number }): Promise<void> {
    return call('/coop/deleteDoc', { id: '' + id }) as Promise<void>
  }
}

/*
const url = real ? '/coop/coopData' : '/coop.json'
const coopData = await (await fetch(url)).json() as CoopData
setCoop({ ...coopData, load: coopLoad, uploadPhoto: coopUploadPhoto })
console.log("setCoop", coopData
*/


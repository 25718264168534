import React, { useContext, useState } from 'react'
import { Container, Card, Nav, Tab, Form } from 'react-bootstrap'
import { CoopContext } from './context'
import { Redirect } from 'react-router'
import { FaLocationArrow } from 'react-icons/fa';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { MyButton } from './MyButton'
import draftToHtml from 'draftjs-to-html'
import api from './api';

/*
interface MessageProps {
}

class Message extends React.Component<MessageProps, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      contentState: {} // ContentState JSON
    };
  }

  onContentStateChange: Function = (contentState: any) => {
    this.setState({
      contentState,
    });
  };

  render() {
    const { contentState } = this.state;
    return (
    )
  }
}
*/


function Invitation() {
  return (
    <>
      <Form.Group controlId="formGroupPlace">
        <Form.Label>Místo schůze:</Form.Label>
        <Form.Control name="misto" />
      </Form.Group>
      <Form.Group controlId="formGroupTime">
        <Form.Label>Termín konání:</Form.Label>
        <Form.Control name="termin" />
      </Form.Group>
      <Form.Group controlId="formGroupReadOnly">
        <Form.Label>Program schůze:</Form.Label>
        <Form.Control plaintext readOnly
          defaultValue="Program schůze můžete zapsat do části Oznámení" />
      </Form.Group>
      <Card.Text>
        Pro vložení pozvánky vyplňte Pozvánku níž a text Oznámení.
        Případně můžete vyplnit pouze Oznámení nebo pouze vložit Přílohu.
    </Card.Text>
    </>)
}

function Attachment() {
  return (
    <>
      <Card.Text>
        Pokud chcete, můžete k Pozvánce nebo Oznámení přiložit soubor:
      </Card.Text>

      <Form.Group controlId="formGroupAttach">
        <Form.Label>Zvolte soubor:</Form.Label>
        <Form.Control name="file" type="file" />
      </Form.Group>
      {/*
      <Form.Group controlId="formGroupFileName">
        <Form.Label>Zadejte název souboru:</Form.Label>
        <Form.Control name="name" placeholder="Název souboru..." />
      </Form.Group>
      */}
    </>
  )
}



export function Submit() {
  const coop = useContext(CoopContext)
  const [submitted, setSubmitted] = useState(false)
  const [editor, setEditor] = useState(null) // DraftEditor
  const [edit, setEdit] = useState<any>(null)
  if (submitted) return <Redirect to='/' />
  // const auth = useContext(AuthContext)
  //  if (!auth.email) return <Redirect to='/signin' />
  const getData = (fD: null | FormData) => {
    console.log("editor", editor, edit)
    if (!fD) throw new Error("No form??")
    fD.append('submit', '1')
    if (edit) fD.append('message', draftToHtml(edit))
    return fD
  }
  const postSubmit = async () => {
    await coop.load()
    setSubmitted(true)
  }
  const edStyle = {
    border: '1px solid #ccc',
    minHeight: '300px', padding: '6px'
  }
  return (
    <Container fluid className="mt-3">
      <Form>
        <Card>
          <Card.Header as='h3'>Vložit novou...</Card.Header>
          <Card.Subtitle className='ml-3 mt-3 text-muted'>
            Vložení Pozvánky na schůzi či jiného Oznámení...
          </Card.Subtitle>
          <Card.Body className='ml-3'>
            <Tab.Container defaultActiveKey="invitation">
              <Nav variant="pills">
                <Nav.Item>
                  <Nav.Link eventKey="invitation">Pozvánka</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="message">Oznámení</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="attachment">Příloha</Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content className='mt-2'>
                <Tab.Pane eventKey="invitation">
                  <Invitation />
                </Tab.Pane>
                <Tab.Pane eventKey="message">
                  <div style={edStyle}>
                    <Editor
                      onContentStateChange={(ed) => setEdit(ed)}
                      editorRef={(ref: any) => { setEditor(ref) }}
                      localization={{ locale: 'cs' }}
                    />
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="attachment">
                  <Attachment />
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </Card.Body>
          <Card.Footer>
            <MyButton variant='success' icon={<FaLocationArrow />}
              getData={(fD) => { return getData(fD) }}
              onAction={api.coopSubmit}
              successAlert='Příspěvek byl vložen!'
              onOK={postSubmit}
              text="Vložit" />
          </Card.Footer>
        </Card>
      </Form>
    </Container >
  )
}

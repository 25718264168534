import React, { useContext } from 'react'
import { Redirect } from 'react-router'
// import 'isomorphic-unfetch' // spis api.ts

import { AuthContext } from './context';
import { Table, Form, Card, Container, Row, Col } from 'react-bootstrap';
import { FaTrash, FaPlus } from 'react-icons/fa';
import { MyButton } from './MyButton';
import api from './api';

export function LoginRow({ email }: { email: string }) {
  const auth = useContext(AuthContext)
  return (
    <tr>
      <td>{email}</td>
      <td>
        <MyButton variant="link" icon={<FaTrash />}
          getData={() => { return { email } }}
          dangerAlert={`Přístup přes účet ${email} bude zablokován!`}
          onAction={() => { return api.settingsDenyAccess({ email }) }}
          onOK={() => { return auth.load() }}
        />
      </td>
    </tr>
  )
}

export function Access() {
  const auth = useContext(AuthContext)
  if (!auth.email) return <Redirect to='/signin' />
  return (
    <Container className='mt-3'>
      <Card>
        <Card.Header as='h3'>Uživatelé</Card.Header>
        <Card.Body>
          <Card.Subtitle className="mb-2 text-muted">
            Seznam uživatelů, kteří můžou vkládat příspěvky:
          </Card.Subtitle>
          <Table striped className='mt-3'>
            <tbody>
              {auth.logins.map(l => <LoginRow key={l.email} email={l.email} />)}
            </tbody>
          </Table>
          <Card.Subtitle className="mb-2 mt-4 text-muted">
            Přidat přístup dalšímu uživateli:
          </Card.Subtitle>
          <Form>
            <Row>
              <Col>
                <Form.Control type="email" name="email" />
                <Form.Text className="text-muted">
                  Na zadaný e-mail bude zaslán odkaz pro nastavení hesla.
                </Form.Text>
              </Col>
              <Col>
                <MyButton icon={<FaPlus />} text='Přidat'
                  onAction={(fD: FormData) => api.settingsCreateLogin(fD)}
                  onOK={auth.load} resetForm
                />
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card >
    </Container >
  )
}
/*
                  onAction={(fD: FormData) => api.settingsCreateLogin(
                    { email: '' + fD!.get('email') })}
                    */
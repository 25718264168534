import React from "react";
import { CoopData, CoopAuthData } from './perl'

export type CoopContextData = CoopData & {
  load: () => void
}
export type AuthContextData = CoopAuthData & {
  load: () => void
}

export const CoopContext =
  React.createContext<CoopContextData>(null as unknown as CoopContextData)
export const AuthContext =
  React.createContext<AuthContextData>(null as unknown as AuthContextData)

/*
export type Udalost = {
  id: number,
  datum: string,
  title: string,
  email: string,
  isMeeting?: boolean,
  contentHtml?: null | string,
  fileName?: null | string,
}

const events: Udalost[] = [
  {
    id: 1,
    datum: "10.06.2019",
    title: "Členská schůze č. 39 z 3.6.2019, bytové družstvo Nad školkou - zápis ze schůze	",
    email: "libor.kelnar@seznam.cz",
  }, {
    id: 2,
    datum: "09.05.2019",
    title: "Pozvánka na domovní schůzi 3.6.2019 - zadní vchod domu I.Šustaly 1109 , Kopřivnice	",
    email: "libor.kelnar@seznam.cz",
  }, {
    id: 3,
    datum: "26.02.2019",
    title: "zápis ze schůze družstva č. 38	",
    email: "libor.kelnar@seznam.cz",
  }, {
    id: 4,
    datum: "10.12.2018",
    title: "zápis ze schůze 26.11.2018	",
    email: "libor.kelnar@seznam.cz",
  }, {
    id: 7,
    datum: "10.03.2016",
    title: "Schůze 29.3.2016 v 17.00 hod., Kopřivnice, ul. I. Šustaly čp. 1109, hala u zadního vchodu",
    email: "libor.kelnar@seznam.cz",
    isMeeting: true,
    contentHtml:
      `1) hospodaření družstva
    2) přijetí nového člena
    3) seznámení s probíhajícím soudním jednáním za přítomnosti advokáta
    4) různé`,
    fileName: 'pozvánka na schůzi 29.3.2016.doc'
  }, {
    id: 5,
    datum: "07.12.2017",
    title: "Zápis č. 36 ze dne 27.11.2017	",
    email: "libor.kelnar@seznam.cz",
  }, {
    id: 6,
    datum: "04.04.2016",
    title: "Zápis č. 34 ze dne 29.3.2016",
    email: "libor.kelnar@seznam.cz",
  }, {
    id: 8,
    datum: "10.03.2016",
    title: "Schůze 29.3.2016 v 17.00 hod., Kopřivnice, ul. I. Šustaly čp. 1109, hala u zadního vchodu",
    email: "libor.kelnar@seznam.cz",
    isMeeting: true,
    contentHtml:
      `1) hospodaření družstva
    2) přijetí nového člena
    3) seznámení s probíhajícím soudním jednáním za přítomnosti advokáta
    4) různé`,
    fileName: 'pozvánka na schůzi 29.3.2016.doc'
  }
]
*/


/*
public:
customPhotoId
domain, shortTitle, longTitle,
news[] { id, date, title, email, isMeeting, contentMD?, fileName? }
lat, lng, streetNo, zipCity,
contactEmail, contactPhone,
legalNo,
recordMD
articles[] { date, title, previewMD?, pdfUrl }
---
auth:
email
invoices[] { date, vs, amount, paid }
logins[] { email }
*/

/*
const coop2 = {
  customPhotoUrl: null,
  domain: 'antoninska18',
  shortTitle: 'Antonínská 18',
  longTitle: 'Antonínská 18, bytové družstvo',
  events,
  coords: { lat: 51.5258541, lng: -0.08040660000006028 },
  streetNo: 'Antonínská 564/18',
  zipCity: '60200 Brno',
  legalNo: '29190088',
  contactEmail: 'libor.kelnar@seznam.cz',
  contactPhone: null,
  recordMD: null,
  articles: []
}

const coop: CoopData =
{
  "id": 3, "legalNo": "48032018", "longTitle": "FIALKA PRAHA 5",
  "domain": "fialka", "shortTitle": "FIALKA PRAHA 5",
  "events": [
    {
      "createdCze": "05.02.2019", "hasContent": 1, "id": 3,
      "messageHtml": "Schuze 584009", "misto": "Fialka 5",
      "subject": "Schůze 2019-02-05, Fialka 5", "termin": "2019-02-05"
    },
    {
      "createdCze": "17.01.2019", "hasContent": 1, "id": 2,
      "messageHtml": "Schuze 67497", "misto": "Fialka 5",
      "subject": "Schuze 2019-01-17, Fialka 5", "termin": "2019-01-17"
    }
  ],
}
*/

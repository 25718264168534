import React, { useContext } from "react"
import { CoopContext } from "./context"
import ReactResizeDetector from "react-resize-detector"
import Iframe from "react-iframe"
import TurndownService from 'turndown'
import ReactMarkdown from 'react-markdown'

const turndownService = new TurndownService()


/*

	var width = $('#block-map').width();// - 2*10;
	var height = Math.round(10 * width / 16);

	var sidlo = server.coop.sidlo;
	if (server.coop.sidloFix != null) sidlo = server.coop.sidloFix;
	var iframeHtml = "<iframe width=" + width + " height=" + height + " frameborder=0 " +
		"scrolling=no style='padding: 0em' marginheight=0 marginwidth=0 " +
		"src='//maps.google.com/maps?q=" + encodeURIComponent(sidlo) +
		"&amp;output=embed'></iframe>";
  if (sidlo && sidlo != '') $('#block-map-placeholder').html(iframeHtml);
  */
export function MyGmap() {
  const coop = useContext(CoopContext)
  const address = coop.gmapsAddress ||
    `${coop.streetNo}, ${coop.zipCity}, Czechia`
  const src =
    `//maps.google.com/maps?q=${encodeURIComponent(address)}&output=embed`
  return (
    <ReactResizeDetector handleWidth>
      {({ width }: { width: number }) =>
        <Iframe
          width={`${width - 1}px`}
          height={`${Math.round(0.75 * width)}px`}
          frameBorder={0}
          scrolling="no"
          url={src}
          //display="initial"
          styles={{ padding: '0em', marginheight: '0', marginwidth: '0' }}
          position="relative" />}
    </ReactResizeDetector>
  )
}

export function Turndown({ html }: { html: string }) {
  let markdown
  try {
    markdown = turndownService.turndown(html || '')
  } catch (e) {
    markdown = html // pri chybe aspon rozbite...
  }
  return <ReactMarkdown source={markdown} />
}
import React, { useContext, useEffect, useState } from 'react'
import { Container, Card, Form } from 'react-bootstrap'
import 'isomorphic-unfetch'
import { AuthContext } from './context';
import { MyButton } from './MyButton';
import { Redirect } from 'react-router';
import api from './api';

export function LoginForm() {
  const auth = useContext(AuthContext)
  return (
    <Form>
      <Form.Group controlId="formBasicEmail">
        <Form.Label>Vaše e-mailová adresa</Form.Label>
        <Form.Control type="email" name="email" />
        <Form.Text className="text-muted">
          Přihlásit se můžou osoby, které mají oprávnění od předsedy družstva.
        </Form.Text>
      </Form.Group>
      <MyButton className="float-right" variant="link" text="Zapoměl(a) jsem"
        getData={(fD: FormData | null) => { return { email: "" + fD!.get("email") } }}
        validate={(a: any) => { if (!a.email) throw new Error("Vyplňte email") }}
        onAction={(a) => api.loginSendReset(a)}
        successAlert="Na Váš e-mail byl odeslán odkaz pro nastavení hesla"
      />
      <Form.Group controlId="formBasicPassword">
        <Form.Label>Heslo</Form.Label>
        <Form.Control type="password" name="password" />
        <Form.Text className="text-muted">
          Pokud si nepamatujete heslo, vyplňte e-mail a klikněte na
          Zapoměl(a) jsem.
        </Form.Text>
      </Form.Group>
      <MyButton variant="primary" text='Přihlásit'
        onAction={(fd: FormData) => api.loginAuthorize(fd)}
        onOK={() => { return auth.load() }}
      />
    </Form>
  )
}

export function SignOut() {
  const auth = useContext(AuthContext)
  useEffect(() => {
    async function logout() {
      await api.loginLogout()
      await auth.load()
    }
    if (auth.email) logout()
  }, [auth])
  return auth.email ? <>...</> : <Redirect to="/" />
}

export function SignIn() {
  const auth = useContext(AuthContext)
  if (auth.email) {
    // query params?
    return <Redirect to="/" /> // back? returnUrl prop?
  }
  /*
      <Card>
        <Card.Title>Přihlášení</Card.Title>
        <Button onClick={onSignIn} variant="success">Přihlásit</Button>
      </Card>
      */
  return (
    <Container className='mt-5'>
      <Card>
        <Card.Header as='h3'>Přihlášení</Card.Header>
        <Card.Body>
          <Card.Subtitle className='mb-3 text-muted'>Pro vkládání aktualit je nutné se přihlásit.</Card.Subtitle>
          <LoginForm />
        </Card.Body>
      </Card>
    </Container>
  )
}

export function ResetPassword({ email, reset }: { email: string, reset: string }) {
  const [changed, setChanged] = useState(false);
  if (changed) return <Redirect to="/signin" />
  return (
    <Container className='mt-5'>
      <Card>
        <Card.Header as='h3'>Nastavení přístupového hesla</Card.Header>
        <Card.Body>
          <Form>
            <input type="hidden" name="email" value={email} />
            <input type="hidden" name="reset" value={reset} />
            <Form.Group controlId="formBasicPassword1">
              <Form.Label>Zvolte si přihlašovací heslo</Form.Label>
              <Form.Control type="password" name="password1" />
            </Form.Group>
            <Form.Group controlId="formBasicPassword2">
              <Form.Label>Zadejte heslo znovu pro kontrolu</Form.Label>
              <Form.Control type="password" name="password2" />
            </Form.Group>
            <MyButton variant="primary" text='Uložit heslo'
              onAction={(fd: FormData) => api.loginResetPassword(fd)}
              successAlert="Heslo bylo změněno, nyní se přihlašte."
              onOK={() => { setChanged(true) }} // spis alert a refresh?
            />
          </Form>
        </Card.Body>
      </Card>
    </Container >
  )
}
